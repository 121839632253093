import { annotation, ScaleOverlayTool } from '@cornerstonejs/tools';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { logDebug } from '@worklist-2/core/src';

// To Reset scale overlay tool ruler on the viewport
const resetScaleOverlay = (viewport, activeViewportId) => {
	const renderingEngine = getDefaultRenderingEngine();
	const annotationManager = annotation?.state?.getAnnotationManager();
	const allAnnotations = annotationManager?.getAllAnnotations();
	const hasScaleOverlayTool = allAnnotations?.some(
		annotationTool => annotationTool?.metadata?.toolName === ScaleOverlayTool.toolName
	);

	if (!allAnnotations?.length || !hasScaleOverlayTool) {
		return;
	}

	if (!viewport) {
		return;
	}

	const preZoomValue = viewport.getZoom();
	const scaleToFit = Math.max(preZoomValue - 0.01, 0);
	viewport.setZoom(scaleToFit); // hack to trigger re-render after adding scale overlay tool
	renderingEngine.renderViewport(activeViewportId);
	viewport.setZoom(preZoomValue); // reset zoom value after adding scale overlay tool
	logDebug('IV::', `ScaleOverlayTool re-rendered to viewport`, `${scaleToFit}, ${preZoomValue}`);
};

export default resetScaleOverlay;
