import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import createViewport from '../features/DocumentViewer/utils/createViewport';
import debounce from 'lodash/debounce';
import _ from 'lodash';

const TemplateManagerContext = createContext({});

const TemplateManagerContextProvider = ({ children }) => {
	const [viewports, setViewports] = useState([createViewport()]);
	const [templates, setTemplates] = useState([]);
	const [isTemplateListReset, setResetTemplateList] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState({});
	const [templateSearchString, setSearchString] = useState('');
	const [isLoadingTemplates, setTemplatesLoading] = useState(false);
	const [blankTemplates, setBlankTemplates] = useState([]);
	const [bestMatchedTemplate, setBestMatchedTemplate] = useState();
	const [templateHover, setTemplateHover] = useState(false);
	const [allBestMatchTemplates, setAllBestMatchedTemplate] = useState([]);
	const [newWindowSelectedTemplate, setNewWindowSelectedTemplate] = useState({});
	const [templatesLoaded, setTemplatesLoaded] = useState(false);

	//  Dynamic Bookmarks
	const [customPickLists, setCustomPickLists] = useState([]);
	const [customFields, setCustomFields] = useState([]);

	useEffect(() => {
		// Store the copy of Manually selected Template in Context
		if (selectedTemplate && !selectedTemplate.isPreview) {
			// isPreview marked in the TemplateToolbar
			const selected = templates?.find(template => template?.id === selectedTemplate.id);
			setSelectedTemplate(selected || selectedTemplate);
		}
	}, [templates, selectedTemplate]);

	useEffect(() => {
		if (!_.isEmpty(newWindowSelectedTemplate)) setSelectedTemplate(newWindowSelectedTemplate);
	}, [newWindowSelectedTemplate]);

	const clearTemplateViewPort = () => {
		setViewports([
			{
				document: null,
			},
		]);
		setTemplateHover(false);
	};

	const setTemplateHoverDebounced = useCallback(
		debounce(payload => {
			setTemplateHover(payload);
		}, 2500)
	);

	return (
		<TemplateManagerContext.Provider
			value={{
				viewports,
				setViewports,
				selectedTemplate,
				setSelectedTemplate,
				templates,
				setTemplates,
				blankTemplates,
				setBlankTemplates,
				isLoadingTemplates,
				setTemplatesLoading,
				bestMatchedTemplate,
				setBestMatchedTemplate,
				templateHover,
				setTemplateHover,
				setTemplateHoverDebounced,
				clearTemplateViewPort,
				templateSearchString,
				setSearchString,
				isTemplateListReset,
				setResetTemplateList,
				allBestMatchTemplates,
				setAllBestMatchedTemplate,
				customPickLists,
				setCustomPickLists,
				customFields,
				setCustomFields,
				newWindowSelectedTemplate,
				setNewWindowSelectedTemplate,
				templatesLoaded,
				setTemplatesLoaded,
			}}
		>
			{children}
		</TemplateManagerContext.Provider>
	);
};

const useTemplateManagerContext = () => useContext(TemplateManagerContext);

export { TemplateManagerContext, useTemplateManagerContext, TemplateManagerContextProvider as default };
